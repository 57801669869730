<template>
  <m-container>
    <page-title previous-location :page-title="exam.title"></page-title>
    <m-container>
      <v-card flat class=" border border-solid border-radius-8 border-primary">
        <m-container class="pa-8">
          <h2 class="mb-5">{{ exam.title }}</h2>
          <v-row>
            <v-col cols="2" class="d-flex">
              <h4 class="mb-2">Total Marks: &nbsp;</h4>
              <p>{{ exam.points }}</p>
            </v-col>
            <v-col class="d-flex">
              <h4 class="mb-2">Duration: &nbsp;</h4>
              <p>{{ exam.duration }} minutes</p>
            </v-col>
          </v-row>
          <h4 class="mb-2">Instruction:</h4>
          <p class="mb-3">{{ exam.instruction }}</p>
          <v-row justify="center" align="center" class="my-6 flex-column">
            <p>Click the start button to start the exam</p>
            <v-btn
              depressed
              class="white--text bg-primary pa-6 mt-6 font-weight-bold font-size-20"
              @click="startExam"
              >Start Exam
            </v-btn>
          </v-row>
        </m-container>
      </v-card>
    </m-container>
  </m-container>
</template>

<script>
import { ExamProvider } from "#ef/exam/mixins";

export default {
  name: "ExamEntry",
  mixins: [ExamProvider],
  props: {
    examId: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      loadingExam: false,
      exam: undefined
    };
  },
  async created() {
    this.exam = await this.getExamDetails();
    if (!this.exam) {
      this.$router.go(-1);
    }
  },
  methods: {
    async startExam() {
      if (this.loadingExam) return;
      try {
        this.loadingExam = true;
        const { student_exam_id: studentExamId } = await this.$store.dispatch(
          "studentExam/start",
          {
            examId: this.exam.exam_id ?? this.exam.id,
            batchId: this.$ielts_batch_id
          }
        );
        if (this.exam.has_sections) {
          await this.$router.replace({
            name: "studentsAppearSectionExamRoutes",
            params: {
              appear: "appear",
              testId: studentExamId,
              sectionId: 0
            }
          });
        } else {
          await this.$router.push({
            name: "studentsAppearExamRoutes",
            params: { appear: "appear", testId: studentExamId }
          });
        }
      } catch (e) {
        this.$logger.log(e);
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.loadingExam = false;
      }
    }
  }
};
</script>

<style scoped lang="scss"></style>
